<template>
  <div class="original-warning">
    <p class="original-warning__title">注意事項</p>
    <ul class="original-warning__list">
      <li class="original-warning__list__text" v-if="!isExemption">クレジットカードを入力した時点で月額料金の支払いが開始されます。</li>
      <li class="original-warning__list__text">Facebookグループへの参加申請の承認には3営業日ほどお時間をいただく場合がございます。</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    // 例外ユーザーかどうか
    isExemption: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';

.original-warning {
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  font-size: 1rem;
  &__title {
    margin: 0;
  }
  &__list {
    padding-left: 0;
    margin-top: 5px;
    list-style: none;
    &__text {
      line-height: 2rem;
      &::before {
        content: "・";
      }
    }
  }
}
</style>
